import React from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const SliderMainZeroTwo= () => (
 <div className="container" id="hometop">
    <div className="row align-items-center">
          <div className="col-md-6 m-auto">
              <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600}>
              <h2 className="text-center">Join the Beta</h2>
              </Reveal>
              <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={600}>
              <p className="lead text-center">
              Beta registrations are open to both store owners and users. <br/> Simply join our Discord to receive an invitation to the PopSpot.io Beta launch.
              </p>
              </Reveal>
              <div className="spacer-10"></div>
              <Reveal className='onStep' keyframes={fadeInUp} delay={800} duration={600}>
              <div className='d-flex justify-content-center'>
                <span id='discordgg' onClick={()=> window.open("https://discord.gg/MEtKfTpmYB", "_empty")} className="btn-main3 inline lead">Join our Discord</span>
              </div>
              <div className="spacer-single"></div>
              <div className="spacer-double"></div>
              </Reveal>
          </div>
      </div>
    </div>
);
export default SliderMainZeroTwo;
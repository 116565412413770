import React, { useEffect, useState } from "react";
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import { 
  Link, 
  useNavigate, 
  useMatch,
  useResolvedPath
} from "react-router-dom";
import useOnclickOutside from "react-cool-onclickoutside";
import { HashLink} from 'react-router-hash-link';


setDefaultBreakpoints([
  { xs: 0 },
  { l: 1199 },
  { xl: 1200 }
]);

const NavLink = (props) => {
  let resolved = useResolvedPath(props.to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link
      {...props}
      className={ match ? 'active' : 'non-active'}
    />
  )
};



const Header = function({ className }) {

    const [openMenu, setOpenMenu] = React.useState(false);
    const [openMenu1, setOpenMenu1] = React.useState(false);
    const [openMenu2, setOpenMenu2] = React.useState(false);
    const [openMenu3, setOpenMenu3] = React.useState(false);
    const handleBtnClick = () => {
      setOpenMenu(!openMenu);
    };
    const handleBtnClick1 = () => {
      setOpenMenu1(!openMenu1);
    };
    const handleBtnClick2 = () => {
      setOpenMenu2(!openMenu2);
    };
    const handleBtnClick3 = () => {
      setOpenMenu3(!openMenu3);
    };
    const closeMenu = () => {
      setOpenMenu(false);
    };
    const closeMenu1 = () => {
      setOpenMenu1(false);
    };
    const closeMenu2 = () => {
      setOpenMenu2(false);
    };
    const closeMenu3 = () => {
      setOpenMenu3(false);
    };

    const scrollWidthOffset = (el) => {
      const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
      const yOffset = -100; 
      window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    };

    const scrollWidthOffset2 = (el) => {
      const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
      const yOffset = -250; 
      window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    };

    const scrollWidthOffset3 = (el) => {
      const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
      const yOffset = -400; 
      window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    };

    const ref = useOnclickOutside(() => {
      closeMenu();
    });
    const ref1 = useOnclickOutside(() => {
      closeMenu1();
    });
    const ref2 = useOnclickOutside(() => {
      closeMenu2();
    });
    const ref3 = useOnclickOutside(() => {
      closeMenu3();
    });

    const [showmenu, btn_icon] = useState(false);


    useEffect(() => {
    const header = document.getElementById("myHeader");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
        btn_icon(false);
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
          totop.classList.add("show");
          
        } else {
          header.classList.remove("sticky");
          totop.classList.remove("show");
        } if (window.pageYOffset > sticky) {
          closeMenu();
        }
      });
      return () => {
        window.removeEventListener("scroll", scrollCallBack);
      };
    }, []);

    return (
    <header className={`navbar white ${className}`} id="myHeader">
     <div className='container'>
       <div className='row w-100-nav'>
          <div className='logo px-0'>
              <div className='navbar-title navbar-item'>
                <NavLink to="/">
                <img
                    src="/img/logo.png"
                    className="img-fluid d-block"
                    alt="#"
                  />
                  <img
                    src="/img/logo-2.png"
                    className="img-fluid d-3"
                    alt="#"
                  />
                  <img
                    src="/img/logo-3.png"
                    className="img-fluid d-4"
                    alt="#"
                  />
                  <img
                    src="/img/logo-light.png"
                    className="img-fluid d-none"
                    alt="#"
                  />
                </NavLink>
              </div>
          </div>
                    
              <BreakpointProvider>
                <Breakpoint l down>
                  {showmenu && 
                  <div className='menu'>
                    <div className='navbar-item'>
                      <div ref={ref}>
                        <HashLink smooth to="/#hometop" scroll={(el) => scrollWidthOffset3(el)}>
                          Home
                        <span className='lines'></span>
                        </HashLink>
                      </div>
                    </div>
                    <div className='navbar-item'>
                      <div ref={ref1}>
                        <HashLink smooth to="/#howitworks" scroll={(el) => scrollWidthOffset3(el)}>
                          How it Works
                        <span className='lines'></span>
                        </HashLink>
                      </div>
                    </div>
                    <div className='navbar-item'>
                      <div ref={ref2}>
                        <HashLink smooth to="/#roadmap" scroll={(el) => scrollWidthOffset3(el)}>
                          The Roadmap
                          <span className='lines'></span>
                        </HashLink>
                      </div>
                    </div>
                    <div className='navbar-item'>
                      <HashLink smooth to="/#team" scroll={(el) => scrollWidthOffset3(el)}>
                        The Team
                        <span className='lines'></span>
                      </HashLink>
                    </div>
                    <div className='navbar-item'>
                      <div ref={ref3}>
                        <HashLink smooth to="/#joinlist" scroll={(el) => scrollWidthOffset2(el)}>
                          Join the Beta
                          <span className='lines'></span>
                        </HashLink>
                      </div>
                    </div>
                  </div>
                  }
                </Breakpoint>

                <Breakpoint xl>
                  <div className='menu'>
                  <div className='navbar-item'>
                       <HashLink smooth to="/#hometop" scroll={(el) => scrollWidthOffset(el)}>
                        Home
                      <span className='lines'></span>
                      </HashLink>
                    </div>
                    <div className='navbar-item'>
                       <HashLink smooth to="/#howitworks" scroll={(el) => scrollWidthOffset(el)}>
                        How it Works
                      <span className='lines'></span>
                      </HashLink>
                    </div>
                    <div className='navbar-item'>
                      <HashLink smooth to="/#roadmap" scroll={(el) => scrollWidthOffset(el)}>
                        The Roadmap
                        <span className='lines'></span>
                      </HashLink>
                    </div>
                    <div className='navbar-item'>
                      <HashLink smooth to="/#team" scroll={(el) => scrollWidthOffset(el)}>
                        The Team
                        <span className='lines'></span>
                      </HashLink>
                    </div>
                    <div className='navbar-item'>
                      <HashLink smooth to="/#joinlist" scroll={(el) => scrollWidthOffset(el)}>
                        Join the Beta
                        <span className='lines'></span>
                      </HashLink>
                    </div>
                  </div>
                </Breakpoint>
              </BreakpointProvider>

              <div className='mainside'>
                <div className='connect-wal'>
                  <HashLink to="/#joinlist" scroll={(el) => scrollWidthOffset(el)}> Sign Up for the Beta </HashLink>
                </div>
              </div>
                  
      </div>

        <button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
          <div className="menu-line white"></div>
          <div className="menu-line1 white"></div>
          <div className="menu-line2 white"></div>
        </button>

      </div>     
    </header>
    );
}
export default Header;
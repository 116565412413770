import React from "react";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "PopSpot.io gets announced",
    years: "October 30st, 2022",
    content:
      "PopSpot is officially announced on public and social channels.",
  },
  {
    id: 2,
    title: "PopSpot.io Twitter space",
    years: "November 15th, 2022",
    content:
      "PopSpot team holds an AMA for anyone interested in learning more.",
  },
  {
    id: 3,
    title: "Beta Registration opens for Shopify Partner App",
    years: "November 1st, 2022",
    content:
      "Parters, users and community members can register for our closed Beta.",
  },
];

const experienceData = [
  {
    id: 1,
    title: "Beta invites are sent to selected Shopify Partners ",
    years: "November, 2022",
    content:
      "Shopify partners are invited to join the PopSpot Beta.",
  },
  {
    id: 2,
    title: "Initial Portfolio of vendor stores gets announced",
    years: "December, 2022",
    content:
      "The first set of stores joining the launch of PopSpot.io gets announced.",
  },
  {
    id: 3,
    title: "PopSpot Vendor Suite releases on the Shopify.",
    years: "December 2022",
    content:
      "The first version of the PopSpot Shopify integration and app releases.",
  },
];

function Roadmap() {
  return (
    <section className="tf-section popular-collection">     
      <div className="themesflat-container">
          <div className="wrap-heading">
              <div className="content">
                  <p className="sub-heading mt-4 mb-4">Our Roadmap contains the following objectives. It is a living and breathing document to ensure our team is well positioned to drive towards these milestones transparently and decisively. Certain roadmap objectvies have been made contingent on passing dinstict funding goals.
                  </p>
              </div>
          </div>
          <div className="row ml-5 mt-5">
            <div className="col-md-6">
              <div className="timeline check bg-white rounded shadow-dark padding-30 overflow-hidden">
                {educationData.map((education) => (
                  <Timeline key={education.id} education={education} />
                ))}
                <span className="line"></span>
              </div>
            </div>

            <div className="col-md-6">
              <div className="spacer d-md-none d-lg-none" data-height="30"></div>
              <div className="timeline check bg-white rounded shadow-dark padding-30 overflow-hidden">
                {experienceData.map((experience) => (
                  <Timeline key={experience.id} education={experience} />
                ))}
                <span className="line"></span>
              </div>
            </div>
          </div>
          
      </div>
    </section>
  );
}

export default Roadmap;
import React, {Component} from 'react';
import Reveal from 'react-reveal';

import { HashLink as Link } from 'react-router-hash-link';

import StartupFeaturesS from './StartupFeaturesS';
import StartupFeaturesL from './StartupFeaturesL';

import mobileview1 from '../../../assets/images/mobileview1.png';
import shopifystorelisting from '../../../assets/images/shopifystorelisting.png';
import mobileshopifyview from '../../../assets/images/mobileshopifyview.png'
import configurepop from  '../../../assets/images/popconfigonshopify.jpg';
import orderconfirmation from  '../../../assets/images/orderconfirmation.png';
import mobilemintpop from  '../../../assets/images/mobilemintpop.png';
import productdetailpage from '../../../assets/images/productdetailpage.jpg';
import confirmpurchase from '../../../assets/images/confirmpurchase.jpg';
import payoutscreen from '../../../assets/images/payoutscreen.jpg';
import checkoutviewmobile from '../../../assets/images/checkoutviewmobile.png';
import purchaseconfirmation from '../../../assets/images/purchaseconfirmation.png';
import mobilepayout from '../../../assets/images/mobilepayout.png';

import Media from 'react-media';

class StartupFeatures extends Component {
    render(){
        return (
            <div>
                <Media queries={{ small: { maxWidth: 768 } }}>
                {matches =>
                    matches.small ? (
                    <StartupFeaturesS/>
                    ) : (
                    <StartupFeaturesL/>
                    )
                }
                </Media>
          </div>
        )
    }
}

export default StartupFeatures;
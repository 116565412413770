import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Scrollbar, A11y   } from 'swiper';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Pagination, Navigation, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/modules/navigation/navigation.scss";
import "swiper/modules/pagination/pagination.scss";
import 'swiper/modules/free-mode/free-mode.min.css'; // Pagination module
import 'swiper/modules/thumbs/thumbs.min.css'; // Pagination module`

import img1 from '../../assets/images/team/mikenedelko.png';
import img2 from '../../assets/images/team/johnosb.png';
import img3 from '../../assets/images/team/seanals.png';


const TeamSliderS = () => {
    const [data] = useState(
        [
            {
                title: '/mnedelko',
                img:  img1,
                link: 'https://github.com/mnedelko'
            },
            {
                title: '/josborn',
                img:  img2,
                link: 'https://github.com/josborn'
            },
            {
                title: '/salsup',
                img:  img3,
                link: 'http://github.com/salsup'
            }
        ]
    );

    return(
        <div className="container--fluid-screen">
            <div className="row align-items-center">
                    <div className="col-md-12 mb-5">
                        <Swiper
                            modules={[Pagination, Autoplay, Scrollbar, A11y ]}
                                slidesPerView={2}
                                spaceBetween={12}
                                autoHeight={true}
                                loop={true}
                                scrollbar={{ draggable: true }}
                                pagination={{
                                    clickable: true,
                                  }}
                                autoplay={{ delay: 3000 }}
                                className="mySwiper"
                                >
                                {
                                    data.map((item,index) => (
                                        <SwiperSlide key={index} >
                                            <div className="swiper-slide">
                                                <div className="slider-item">										
                                                    <div className="sc-card-product explode style2">
                                                        <div className="type-title">
                                                            <span className="git-main2">
                                                                <i className="icon-social-github teamtitle2"></i>
                                                            </span>
                                                            <div>
                                                                <h3> {item.title}</h3>
                                                            </div>
                                                        </div>
                                                        <div className="card-media">
                                                            <a href={item.link} target="_blank" rel="noopener noreferrer"><img src={item.img} alt={item.title}/></a>
                                                        </div>                                      
                                                    </div>  	
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                }

                        </Swiper>
                    </div>
            </div>
        </div>
    );

};

export default TeamSliderS;
import React from 'react';
import SliderMain from '../components/SliderMain';
import Footer from '../components/footer';
import SliderMainZeroTwo from '../components/SliderMainZero2';
import { Helmet } from 'react-helmet-async';

//StartupFeatures
import StartupFeatures from '../components/features/StartupFeatures';

//Roadmap
import Roadmap from "../components/Roadmap";
import MeetTheTeam from '../components/MeetTheTeam';

import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;


const Home= () => {
  
  return (
      <div>
        <section className="jumbotron breadcumb no-bg h-vh" style={{ backgroundImage: `url(${'./img/bg-shape-1.jpg'})` }}>
          <SliderMain />
        </section>

        <section className='container no-top no-bottom'>
          <div className='row' id="howitworks">
            <div className='col-lg-12'>
              <div className='text-center'>
                <h2>How it Works</h2>
                <div className="small-border"></div>
              </div>
              <StartupFeatures />
            </div>
          </div>
        </section>

        <section className='container no-bottom' id="roadmap">
          <div className='row'>
            <div className='col-lg-12'>
              <div className='text-center'>
                <h2 className="heading">The Roadmap</h2>
                <div className="small-border"></div>
              </div>
            </div>
            <div className='col-lg-12'>
              <Roadmap />
            </div>
          </div>
        </section>

        <section className='container section2' id="team">
          <div className='row'>
            <div className='col-lg-12'>
              <div className='text-center'>
                <h2>Meet The Team</h2>
                <div className="small-border"></div>
              </div>
            </div>
          </div>
          <MeetTheTeam />
          <div className="small-border" id="joinlist"></div>
          <SliderMainZeroTwo />
        </section>

        <Footer />

      </div>
  );
}

export default Home;
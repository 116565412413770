import React, {useState, Component} from 'react';
import Reveal from 'react-reveal';

import { HashLink as Link } from 'react-router-hash-link';

import mobileview1 from '../../../assets/images/mobileview1.png';
import shopifystorelisting from '../../../assets/images/shopifystorelisting.png';
import mobileshopifyview from '../../../assets/images/mobileshopifyview.png'
import configurepop from  '../../../assets/images/popconfigonshopify.jpg';
import orderconfirmation from  '../../../assets/images/orderconfirmation.png';
import mobilemintpop from  '../../../assets/images/mobilemintpop.png';
import productdetailpage from '../../../assets/images/productdetailpage.jpg';
import confirmpurchase from '../../../assets/images/confirmpurchase.jpg';
import payoutscreen from '../../../assets/images/payoutscreen.jpg';
import checkoutviewmobile from '../../../assets/images/checkoutviewmobile.png';
import purchaseconfirmation from '../../../assets/images/purchaseconfirmation.png';
import mobilepayout from '../../../assets/images/mobilepayout.png';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, A11y   } from 'swiper';

// import required modules
import { Pagination, Navigation, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/modules/navigation/navigation.scss";
import "swiper/modules/pagination/pagination.scss";
import 'swiper/modules/free-mode/free-mode.min.css'; // Pagination module
import 'swiper/modules/thumbs/thumbs.min.css'; // Pagination module`



function StartupFeaturesS() {
    const [featuresdata] = useState(
        [
            {
                roundicon: 'brands-shoppify',
                roundiconstyle: "roundicon1",
                title: '1. Connect your store front.',
                img:  shopifystorelisting,
                mobileimg: mobileview1
            },
            {
                roundicon: 'icon-equalizer',
                roundiconstyle: "roundicon2",
                title: '2. Configure your POP mint settings.',
                img:  configurepop,
                mobileimg: mobileshopifyview
            }
            ,
            {
                roundicon: 'icon-wallet',
                roundiconstyle: "roundicon3",
                title: '3. Your customer mints the POP post checkout.',
                img:  orderconfirmation,
                mobileimg: mobilemintpop
            }
            ,
            {
                roundicon: 'icon-tag',
                roundiconstyle: "roundicon4",
                title: "4. Your customers' followers buy at a discount.",
                img:  productdetailpage,
                mobileimg: checkoutviewmobile
            }
            ,
            {
                roundicon: 'icon-basket-loaded',
                roundiconstyle: "roundicon5",
                title: '5. PopSpot orders go directly to the vendor.',
                img:  confirmpurchase,
                mobileimg: purchaseconfirmation
            },
            {
                roundicon: 'fa-handsusd',
                roundiconstyle: "roundicon6",
                title: '6. Customer receives your configured sales commission.',
                img:  payoutscreen,
                mobileimg: mobilepayout
            }
        ]
    );

  return (
    <div className="container--fluid-screen">
            <div className="row align-items-center">
                    <div className="col-md-12 mb-5">
                        <Swiper
                            modules={[Pagination, Autoplay, Scrollbar, A11y ]}
                                slidesPerView={1}
                                spaceBetween={12}
                                autoHeight={true}
                                loop={true}
                                scrollbar={{ draggable: true }}
                                pagination={{
                                    clickable: true,
                                    renderBullet: function (index, className) {
                                    return '<span class="' + className + '">' + "</span>";
                                    },
                                  }}
                                autoplay={{ delay: 3000 }}
                                className="mySwiper"
                                >
                                {
                                    featuresdata.map((item,index) => (
                                        <SwiperSlide key={index} >
                                            <div className="swiper-slide">
                                                <div className="slider-item">										
                                                    <div className="sc-card-product explode style2">
                                                        <div className="startupresponsive">
                                                            <div className="nav-item-responsive">
                                                                <span className="icon" id={item.roundiconstyle}>
                                                                    <span className= {item.roundiconstyle}>
                                                                        <i className={item.roundicon}></i>
                                                                    </span>
                                                                </span>
                                                                <div className="break"></div>
                                                                <div className="headertitle">
                                                                    <h3> {item.title}</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-media2">
                                                            <div className="startupresponsive_img">
                                                                <div className="web_img">
                                                                    <img src={item.img} alt={item.title}/>
                                                                </div>
                                                                <div className="phone_img"><img src={item.mobileimg} alt={item.title}/></div>
                                                            </div>
                                                        </div>                                     
                                                    </div>  	
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                }

                        </Swiper>
                    </div>
            </div>
        </div>
    
  );
}

export default StartupFeaturesS;
import React, {Component} from 'react';
import Reveal from 'react-reveal';

import { HashLink as Link } from 'react-router-hash-link';

import StartupFeaturesS from './StartupFeaturesS';

import mobileview1 from '../../../assets/images/mobileview1.png';
import shopifystorelisting from '../../../assets/images/shopifystorelisting.png';
import mobileshopifyview from '../../../assets/images/mobileshopifyview.png'
import configurepop from  '../../../assets/images/popconfigonshopify.jpg';
import orderconfirmation from  '../../../assets/images/orderconfirmation.png';
import mobilemintpop from  '../../../assets/images/mobilemintpop.png';
import productdetailpage from '../../../assets/images/productdetailpage.jpg';
import confirmpurchase from '../../../assets/images/confirmpurchase.jpg';
import payoutscreen from '../../../assets/images/payoutscreen.jpg';
import checkoutviewmobile from '../../../assets/images/checkoutviewmobile.png';
import purchaseconfirmation from '../../../assets/images/purchaseconfirmation.png';
import mobilepayout from '../../../assets/images/mobilepayout.png';

class StartupFeaturesL extends Component {
    render(){
        return(
            <section className="startup_fuatures_area sec_pad">
                <div className="container">
                    </div>
                    <ul className="nav nav-tabs startup_tab" id="myTab" role="tablist">
                        <Reveal effect="fadeInLeft">
                            <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="market-tab" data-toggle="tab" data-bs-toggle="tab" data-bs-target="#storelisting" role="tab" type="button" aria-controls="storelisting" aria-selected="true">
                                <span className="icon"><i className="brands-shoppify"></i></span>
                                <h3>Connect<br/> your <br/>store front</h3>
                            </button>
                            </li>
                        </Reveal>
                        <Reveal effect="fadeInLeft" duration={1400}>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="app-tab" data-toggle="tab" data-bs-toggle="tab" data-bs-target="#configurepopscreen" role="tab" type="button" aria-controls="configurepopscreen" aria-selected="true">
                                    <span className="icon"><i className="icon-equalizer"></i></span>
                                    <h3>Configure your<br/> POP mint <br/> settings</h3>
                                </button>
                            </li>
                        </Reveal>   
                        <Reveal effect="fadeInLeft" duration={2000}>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="hubstaff-tab" data-toggle="tab" data-bs-toggle="tab" data-bs-target="#mintpop" role="tab" type="button" aria-controls="mintpop" aria-selected="true">
                                    <span className="icon"><i className="icon-wallet"></i></span>
                                    <h3>Your customer <br/> mints the POP <br/> post checkout </h3>
                                </button>
                            </li>
                        </Reveal>
                        <Reveal effect="fadeInLeft" duration={2400}>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="engine-tab" data-toggle="tab" data-bs-toggle="tab" data-bs-target="#userdiscount" role="tab" type="button" aria-controls="userdiscount" aria-selected="true">
                                <span className="icon"><i className="icon-tag"></i></span>
                                <h3>Your customers'<br/> followers buy <br/> at a discount.</h3>
                            </button>
                        </li>
                        </Reveal>
                        <Reveal effect="fadeInLeft" duration={2800}>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="business-tab" data-toggle="tab" data-bs-toggle="tab" data-bs-target="#vendorcheck" role="tab" type="button" aria-controls="vendorcheck" aria-selected="true">
                                <span className="icon"><i className="icon-basket-loaded"></i></span>
                                <h3>PopSpot orders <br/> go directly to <br/> the vendor.</h3>
                            </button>
                        </li>
                        </Reveal>
                        <Reveal effect="fadeInLeft" duration={3000}>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="comission-tab" data-toggle="tab" data-bs-toggle="tab" data-bs-target="#comission" role="tab" type="button" aria-controls="comission" aria-selected="true">
                                <span className="icon"><i className="fa-handsusd"></i></span>
                                <h3>Customer receives <br/> your configured<br/> sales commission.</h3>
                            </button>
                        </li>
                        </Reveal>
                
                    </ul>
                    <div className="tab-content startup_tab_content" id="myTabContent"> 
                        <div className="tab-pane fade show active" id="storelisting" role="tabpanel" aria-labelledby="market-tab">
                            <div className="startup_tab_img">
                                <div className="web_img">
                                    <img src={shopifystorelisting} alt=""/>
                                </div>
                                <div className="phone_img"><img src={mobileview1} alt=""/></div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="configurepopscreen" role="tabpanel" aria-labelledby="app-tab">
                            <div className="startup_tab_img">
                                <div className="web_img">
                                    <img src={configurepop} alt=""/>
                                </div>
                                <div className="phone_img"><img src={mobileshopifyview} alt=""/></div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="mintpop" role="tabpanel" aria-labelledby="hubstaff-tab">
                            <div className="startup_tab_img">
                                <div className="web_img">
                                    <img src={orderconfirmation} alt=""/>
                                </div>
                                <div className="phone_img"><img src={mobilemintpop} alt=""/></div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="userdiscount" role="tabpanel" aria-labelledby="engine-tab">
                            <div className="startup_tab_img">
                                <div className="web_img">
                                    <img src={productdetailpage} alt=""/> 
                                </div>
                                <div className="phone_img"><img src={checkoutviewmobile} alt=""/></div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="vendorcheck" role="tabpanel" aria-labelledby="business-tab">
                            <div className="startup_tab_img">
                                <div className="web_img">
                                 <img src={confirmpurchase} alt=""/>
                                </div>
                                <div className="phone_img"><img src={purchaseconfirmation} alt=""/></div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="comission" role="tabpanel" aria-labelledby="business-tab">
                            <div className="startup_tab_img">
                                <div className="web_img">
                                 <img src={payoutscreen} alt=""/>
                                </div>
                                <div className="phone_img"><img src={mobilepayout} alt=""/></div>
                            </div>
                        </div>
                    </div>
            </section>
        )
    }
}

export default StartupFeaturesL;
import React, { useState } from 'react';
import TeamSliderS from './TeamSliderS';
import TeamSliderL from './TeamSliderL';
import Media from 'react-media';

const MeetTheTeam = () => {

    return (
        <div>
            <Media queries={{ small: { maxWidth: 768 } }}>
            {matches =>
                matches.small ? (
                <TeamSliderS/>
                ) : (
                <TeamSliderL/>
                )
            }
            </Media>
      </div>
    )
};

export default MeetTheTeam;
import React, { useRef, useState }from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Pagination, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/modules/pagination/pagination.scss";
import 'swiper/modules/free-mode/free-mode.min.css'; // Pagination module
import 'swiper/modules/thumbs/thumbs.min.css'; // Pagination module`

//Import Hashlink
import { HashLink} from 'react-router-hash-link';

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const SliderMain= () => {
  const scrollWidthOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -95; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
  };

  return(
    <div className="container" id="hometop">
      <div className="row align-items-center">
            <div className="col-md-6">
                <div className="spacer-single"></div>
                <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
                <h6 className=""><span className="text-uppercase color">popspot.io</span></h6>
                </Reveal>
                <div className="spacer-10"></div>
                <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600} triggerOnce>
                <h1 className="">Turn your customers into a sales army</h1>
                </Reveal>
                <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={600} triggerOnce>
                <p className=" lead">
                Ads can't be trusted. Your customers can. PopSpot is a consumer marketplace, where customers use non-fungible entitlements to sell and promote your products with every purchase receipt.
                </p>
                </Reveal>
                <div className="spacer-10"></div>
                <Reveal className='onStep' keyframes={fadeInUp} delay={800} duration={900} triggerOnce>
                <HashLink smooth to="/#howitworks" scroll={(el) => scrollWidthOffset(el)}>
                <span className="btn-main lead">How it works</span></HashLink>
                <div className="mb-sm-30"></div>
                </Reveal>
            </div>
            <div className="col-md-6 xs-hide">
              <Reveal className='onStep' keyframes={fadeIn} delay={900} duration={1500} triggerOnce>
                <Swiper
                    direction={"vertical"}
                    pagination={{
                      clickable: true,
                    }}
                    autoplay={{ delay: 5000 }}
                    modules={[Pagination, Autoplay]}
                    className="mySwiper"
                  >
                    <SwiperSlide><img src="./img/headerslider/popspot1.png" className="lazy img-fluid" alt=""/></SwiperSlide>
                    <SwiperSlide><img src="./img/headerslider/popspot2.png" className="lazy img-fluid" alt=""/></SwiperSlide>
                    <SwiperSlide><img src="./img/headerslider/popspot3.png" className="lazy img-fluid" alt=""/></SwiperSlide>
                    <SwiperSlide><img src="./img/headerslider/popspot4.png" className="lazy img-fluid" alt=""/></SwiperSlide>
                </Swiper>
              </Reveal>
            </div>
        </div>
    </div>
  );
}
  
 
export default SliderMain;
import React from 'react';
import { Link } from 'react-router-dom';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";

//Import Hashlink
import { HashLink} from 'react-router-hash-link';

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const footer= () => {
    const socialList = [
        {
            icon: "ntfs-fl-vt",
            pathname: "https://discord.gg/MEtKfTpmYB"
        },
        {
            icon: "brands-twitter",
            pathname: "https://twitter.com/popspotio"
        },
        {
            icon: "ntfs-fl-tik-tok",
            pathname: "https://www.tiktok.com/@popspotio"
        },
        {
            icon: "brands-youtube",
            pathname: "https://www.youtube.com/channel/UCPs0aERmWKFMuEPBuN4M0tA"
        },
        {
            icon: "brands-github",
            pathname: "https://github.com/popspotio"
        },
        {
            icon: "brands-shoppify",
            pathname: "https://apps.shopify.com/"
        }
        
    ]

    const scrollWidthOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -100; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
      };
  
      const scrollWidthOffset2 = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -250; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
      };
  
      const scrollWidthOffset3 = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -400; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
      };

    return(
    <footer className="footer-light" id="jointhelist">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-sm-6 col-xs-1">
                            <div className="widget">
                                <h5>For Shopify Sellers</h5>
                                <ul>
                                    <li><HashLink smooth to="/#howitworks" scroll={(el) => scrollWidthOffset(el)}>How it Works</HashLink></li>
                                    <li><HashLink smooth to="/#joinlist" scroll={(el) => scrollWidthOffset(el)}>Sign Up for the Beta</HashLink></li>
                                    <li><Link to="">Documentation (coming) </Link></li>
                                    <li><Link to="">Shopify App (coming) </Link></li>
                                    <li><HashLink smooth to="/#roadmap" scroll={(el) => scrollWidthOffset(el)}>Roadmap</HashLink></li>
                                    <li><HashLink smooth to="/#team" scroll={(el) => scrollWidthOffset(el)}>Meet the Team</HashLink></li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-1">
                            <div className="widget">
                                <h5>For PopStop Users</h5>
                                <ul>
                                    <li><HashLink smooth to="/#howitworks" scroll={(el) => scrollWidthOffset(el)}>How it Works</HashLink></li>
                                    <li><HashLink smooth to="/#joinlist" scroll={(el) => scrollWidthOffset(el)}>Sign Up for the Beta</HashLink></li>
                                    <li><Link to="">How to sell on PopSpot (coming)</Link></li>
                                    <li><Link to="">How to add a Pop (coming)</Link></li>
                                    <li><HashLink smooth to="/#roadmap" scroll={(el) => scrollWidthOffset(el)}>Roadmap</HashLink></li>
                                    <li><HashLink smooth to="/#team" scroll={(el) => scrollWidthOffset(el)}>Meet the Team</HashLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-1">
                            <div className="widget">
                                <h5>Community</h5>
                                <ul>
                                    <li><Link to="https://github.com/popspotio">Join us on Github</Link></li>
                                    <li id='discordgg'><Link to="https://discord.gg/MEtKfTpmYB">Join our Discord</Link></li>
                                    <li><Link to="https://www.youtube.com/channel/UCPs0aERmWKFMuEPBuN4M0tA">Videos & Announcements</Link></li>
                                    <li><Link to=""></Link></li>
                                    <li><Link to=""></Link></li>
                                    <li><Link to=""></Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-1">
                            <div className="widget">
                                <h5>Get whitelisted for the Beta</h5>
                                <p>To be invited to our closed Beta join our Discord and we will contact you your invitation link.</p>
                                <div className="spacer-5"></div>
                                <Reveal className='onStep' keyframes={fadeInUp} delay={800} duration={900} triggerOnce>
                                <a id='discordgg' href="https://discord.gg/MEtKfTpmYB" target="_blank">
                                <span className="btn-main2 lead">Join our Discord</span></a>
                                <div className="mb-sm-30"></div>
                                </Reveal>
                                <div className="spacer-10"></div>
                                <small>You will be asked to select your beta-role when joining.</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="subfooter">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="de-flex">
                                    <div className="de-flex-col">
                                        <span onClick={()=> window.open("", "_self")}>
                                            <img alt="" className="f-logo d-1" src="./img/logo.png" />
                                            <img alt="" className="f-logo d-3" src="./img/logo-2-light.png" />
                                            <img alt="" className="f-logo d-4" src="./img/logo-3.png" />
                                            <span className="copy">&copy; Copyright 2022 - PopSpot.io</span>
                                        </span>
                                    </div>
                                    <div className="widget-social style-1 mg-t32">
                                    <ul>
                                        {
                                            socialList.map((item,index) =>(
                                                (() => {
                                                    if (item.pathname) {
                                                      return (
                                                        <li key={index}> <a className="externalLink" target="_blank" href={item.pathname}><i className={item.icon}></i></a></li>
                                                      )
                                                    } else {
                                                      return (
                                                        <li key={index}><Link onClick={()=> window.open("", "_self")}><i className={item.icon}></i></Link></li>
                                                      )
                                                    }
                                                  })()
                                            ))
                                        }
                                    </ul>
                                </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
    );};
export default footer;